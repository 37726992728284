import {
  passphraseStrengthIndicatorOpacity,
  strengthColors,
} from '~/components/passphrase-strength-indicator/resources';
import { usePassphrasePolicy } from '~/components/passphrase-strength-indicator/use-passphrase-policy';

interface Props {
  passphrase: string;
}

const PassphraseStrengthIndicator = ({ passphrase }: Props) => {
  const { totalRules, score, strengthLabel } = usePassphrasePolicy({
    passphrase,
  });

  return (
    <div className="mt-[3%]">
      <div className="box-border flex flex-col">
        <div className="box-border flex items-center justify-center">
          <div className="flex w-full items-center justify-center gap-[5px]">
            {Array.from({ length: totalRules }).map((_, index) => {
              const passedScore = score >= index + 1 ? score : 0;

              return (
                <div
                  key={index}
                  className="flex-0.75 h-[8px] w-full rounded-[5px] bg-[#E0E0E0]"
                  style={{
                    backgroundColor: strengthColors[passedScore],
                    opacity: passphraseStrengthIndicatorOpacity[passedScore] ?? 0.15,
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="flex items-center justify-end">
          <p
            className="mb-0 mt-[3px] h-[1rem] select-none text-right text-[12px] capitalize italic leading-[16px] text-[#888]"
            style={{
              opacity: score ? 1 : 0,
            }}
          >
            {strengthLabel}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PassphraseStrengthIndicator;
