export type Rule = {
  message: string;
  validation?: RegExp;
  verified: boolean;
};

type Result = {
  verified: boolean;
  rules?: Rule[];
  score: number;
  totalRules: number;
  strengthLabel: string;
};

const scoreLabel = ['Bad', 'Weak', 'Good'];

const badRule: Rule = {
  message: 'Must be at least 4 chars.',
  validation: new RegExp('.{4,}'),
  verified: false,
};

const weakRule: Rule = {
  message: 'Should be between 4 to 7 chars.',
  validation: new RegExp('.{4,7}'),
  verified: false,
};

const goodRule: Rule = {
  message: 'Should have at least 7 chars with a mix of numbers, letters.',
  validation: new RegExp('^(?=.*[a-zA-Z]).{7,}$'),
  verified: false,
};

let rules: Rule[] = [badRule, weakRule, goodRule];

export const usePassphrasePolicy = ({ passphrase = '' }): Result => {
  let score = 0;

  const validatedRules = rules?.map((r: Rule) => ({
    message: r.message,
    verified: r.validation?.test(passphrase) || false,
  }));

  if (passphrase?.trim().length > 0 && passphrase?.trim().length < 4) {
    score = 1;
  } else {
    score = validatedRules?.filter((r) => r.verified).length;
  }

  return {
    verified: passphrase?.trim().length > 4,
    score,
    rules: validatedRules,
    totalRules: 3,
    strengthLabel: scoreLabel[score - 1],
  };
};
