export const errorColor = '#c40303';
export const successColor = '#037f06';

export const passphraseStrengthIndicatorOpacity: Record<string, number> = {
  1: 0.3,
  2: 0.5,
  3: 1,
};

export const defaultColor = '#000';
export const badColor = '#D44333';
export const weakColor = '#FFC043';
export const goodColor = '#21A453';

export const strengthColors = [defaultColor, badColor, weakColor, goodColor];
